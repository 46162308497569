import { Dialog, DialogContent } from "@/components/ui/dialog";
import useAuth from "@/hooks/auth";
import {
  sendTokenNotUser,
  verifyTokenNotUser,
  verifyTokenForUserRegister,
} from "@/services/api/Master";
import {
  createStartupByUser,
  getStartupDetailSimple,
  updateStartup,
} from "@/services/api/Startup";
import { register, registerDirect } from "@/services/api/User";
import { checkEmailDomainWithList as checkEmailDomain } from "@/utils/checkEmailDomain";
import { updateHeaders } from "@/utils/httpUtils";
import { CheckCircle, CircleNotch, Eye, EyeSlash } from "@phosphor-icons/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import GoogleSignInButton from "../auth/GoogleSignInButton";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import ErrorMessage from "../ErrorMessage";
import OTPInput from "react-otp-input";

import { useAtom } from "jotai";
import { loginPopup, registerPopup } from "@/Atoms";
import { Checkbox } from "../ui/checkbox";
import { subscribe } from "@/services/api/Newsletter";
import InputTokenForAccountRegister from "./InputTokenForAccountRegister";
import SelectRole from "../SelectRole";

const Register = () => {
  const [openLoginPopup, setOpenLoginPopup] = useAtom(loginPopup);
  const [openRegisterPopup, setOpenRegisterPopup] = useAtom(registerPopup);
  const router = useRouter();
  const [isLoading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [errors, setErrors] = useState({});
  const [startup, setStartup] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [isSendingVerifyEmail, setIsSendingVerifyEmail] = useState(false);
  const [emailVerificationLoading, setEmailVerificationLoading] =
    useState(false);
  const [sendEmailVerificationLoading, setSendEmailVerificationLoading] =
    useState(false);
  const [emailConfirmLoading, setEmailConfirmLoading] = useState(false);
  const [confirmedOfficialEmail, setConfirmedOfficialEmail] = useState(false);

  const [confirmToken, setConfirmToken] = useState("");
  const [checkedNewsletter, setCheckedNewsletter] = useState(true);
  const [registerCodeVerifyInputOpen, setRegisterCodeVerifyInputOpen] =
    useState(false);
  const [registerResponse, setRegisterResponse] = useState(null);
  const { user, login, isLoading: loginLoading } = useAuth();

  const { query } = router;
  const type = query.purpose;
  const startup_id = query.startup_id;

  const firstNameInputRef = useRef();

  const [showPassword, setShowPassword] = useState(false);

  const getStartup = async (id) => {
    const response = await getStartupDetailSimple(id);
    if (response.data) {
      setStartup(response.data);
    }
  };

  const onSendTokenForAccountRegistration = async (e) => {
    e.preventDefault();
    if (!email) {
      return toast.error("Email is required!");
    }

    setSendEmailVerificationLoading(true);
    try {
      const response = await sendTokenNotUser({ email });
      toast.success(
        `Verification token just sent to ${email}, use that token to verify the email.`
      );
      setIsSendingVerifyEmail(true);
    } catch (error) {}
    setSendEmailVerificationLoading(false);
  };

  const onSendToken = async (e) => {
    e.preventDefault();
    if (!email) {
      return toast.error("Email is required!");
    }

    try {
      const isValidDomain = await checkEmailDomain(email, startup.website);
      if (!isValidDomain) {
        return toast.error("The email must match the startup website!");
      }

      setEmailVerificationLoading(true);
      const response = await sendTokenNotUser({ email });
      toast.success(
        `Verification token just sent to ${email}, use that token to verify the email.`
      );
      setIsSendingVerifyEmail(true);
    } catch (error) {
      console.error("Error checking email domain or sending token:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setEmailVerificationLoading(false);
    }
  };

  const onVerifyToken = async (e) => {
    e.preventDefault();
    if (!confirmToken) toast.error("Token is required!");
    setEmailVerificationLoading(true);
    setEmailConfirmLoading(true);
    try {
      const response = await verifyTokenNotUser({ token: confirmToken });
      setConfirmedOfficialEmail(true);
      setIsSendingVerifyEmail(false);
    } catch (error) {
      toast.error("Code is invalid or expired.");
    }
    setEmailVerificationLoading(false);
  };

  const onVerifyTokenUserRegister = async (e) => {
    e.preventDefault();
    if (!confirmToken) toast.error("Token is required!");
    setEmailVerificationLoading(true);
    setEmailConfirmLoading(true);
    try {
      const response = await verifyTokenForUserRegister({
        token: confirmToken,
        email,
      });
      setConfirmedOfficialEmail(true);
      setIsSendingVerifyEmail(false);
      try {
        const access_token = registerResponse.data.data.token;
        const user = registerResponse.data.user;

        updateHeaders({
          Authorization: `Bearer ${access_token}`,
        });

        await login({ email, password, setErrors, notRedirect: true });
        setOpenRegisterPopup(false);
        setRegisterCodeVerifyInputOpen(false)
        // location.reload();
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      toast.error("Code is invalid or expired.");
    }
    setEmailVerificationLoading(false);
    setEmailConfirmLoading(false);
  };

  useEffect(() => {
    if (type === "register" || type === "claim") {
      if (startup_id) {
        getStartup(startup_id);
      } else {
        const startupName = query.startup_name;
        const startupWebsite = query.startup_website;
        if (startupName && startupWebsite) {
          setStartup({
            name: startupName,
            website: startupWebsite,
          });
        }
      }
    }
    firstNameInputRef.current?.focus();
  }, []);

  const submitData = async (event) => {
    event.preventDefault();
    setErrors({});
    let response = null;

    try {
      setLoading(true);
      if (!startup) {
        try {
          response = await registerDirect({
            first_name: firstName,
            last_name: lastName,
            email,
            password,
            is_startup: false,
            role: role,
          });
          setRegisterResponse(response);
          try {
            await sendTokenNotUser({ email });
            toast.success(`We sent code verification to your email ${email}`, {
              duration: 5000,
            });
            setRegisterCodeVerifyInputOpen(true);
          } catch (error) {}
        } catch (error) {
          setErrors(error.response.data.errors);
        }
      } else {
        response = await registerDirect({
          first_name: firstName,
          last_name: lastName,
          email,
          password,
          is_startup: true,
          role: role,
        });

        const access_token = response.data.data.token;
        const user = response.data.user;

        updateHeaders({
          Authorization: `Bearer ${access_token}`,
        });

        await login({ email, password, setErrors, notRedirect: true });
        let redirect = "";

        if (type === "claim" && startup.id) {
          const payload = {
            name: startup.name,
            website: startup.website,
            twitter_url: startup.twitter_url,
            linkedin_url: startup.linkedin_url,
            logo_url: startup.logo_url,
            country_id: startup.country_id,
            creator_id: user.id,
            summary: startup.summary,
            claimed: 1,
            owner_id: user.id,
            owner_email: user.email,
          };

          const responseClaim = await updateStartup(startup.id, payload);
          redirect = `/startups/${responseClaim.data.data.slug}-${responseClaim.data.data.id}`;
        } else if (type === "register") {
          const payload = {
            name: startup.name,
            website: startup.website,
            owner_id: user.id,
            owner_email: user.email,
            creator_id: user.id,
          };

          const responseRegister = await createStartupByUser(payload);
          redirect = `/startups/${responseRegister.data.data.slug}-${responseRegister.data.data.id}`;
        }

        localStorage.setItem("startup-event", type);
        router.push(redirect);
      }

      if (checkedNewsletter && process.env.ENV === "production") {
        try {
          const thisYear = new Date().getFullYear();
          const payload = {
            email,
            tags: ["Customer", String(thisYear), "Web"],
            ...(firstName && { first_name: firstName }),
            ...(lastName && { last_name: lastName }),
          };
          await subscribe(payload);
        } catch (error) {
          console.log(
            "Newsletter subscription failed:",
            error.response?.data?.message
          );
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error("Invalid data!");
        setErrors(error.response.data.errors);
      } else {
        toast.error("An error occurred. Please contact our administrator!");
      }
    } finally {
      setLoading(false);
    }
  };

  function switchPopup() {
    setOpenRegisterPopup(false);
    setOpenLoginPopup(true);
  }

  const handleCheckboxChange = (e) => {
    setCheckedNewsletter(e);
  };

  return (
    <Dialog
      open={registerCodeVerifyInputOpen ? true : openRegisterPopup}
      onOpenChange={setOpenRegisterPopup}
    >
      <DialogContent
        className='p-8 pb-5 bg-white text-background'
        hideCloseButton={registerCodeVerifyInputOpen}
      >
        {!registerCodeVerifyInputOpen ? (
          <div className=''>
            <p className='text-4xl font-semibold tracking-tight lg:text-5xl font-display'>
              Register Now
            </p>
            <p className='mt-3 opacity-60'>
              Create account to start accessing our contents
            </p>
            <form
              onSubmit={submitData}
              className='grid grid-cols-12 gap-4 mt-5'
            >
              <div className='col-span-6 space-y-1.5 text-left'>
                <Label className='text-xs' htmlFor='first_name'>
                  First Name
                </Label>
                <Input
                  ref={firstNameInputRef}
                  type='text'
                  id='first_name'
                  required
                  onChange={(evt) => setFirstName(evt.target.value)}
                  className='border-gray-300 shadow-sm bg-white/5 hover:bg-white/10 focus-visible:ring-0'
                />
                {errors && errors.first_name && (
                  <ErrorMessage message={errors.name[0]} />
                )}
              </div>
              <div className='col-span-6 space-y-1.5 text-left'>
                <Label className='text-xs' htmlFor='last_name'>
                  Last Name
                </Label>
                <Input
                  type='text'
                  id='last_name'
                  required
                  onChange={(evt) => setLastName(evt.target.value)}
                  className='border-gray-300 shadow-sm bg-white/5 hover:bg-white/10 focus-visible:ring-0'
                />
                {errors && errors.name && (
                  <ErrorMessage message={errors.name[0]} />
                )}
              </div>
              <div className='col-span-12 space-y-1.5 text-left'>
                <Label className='text-xs' htmlFor='password'>
                  Role
                </Label>
                <SelectRole
                  isModal={true}
                  value={role}
                  onChange={(value) => setRole(value)}
                />
                {errors && errors.password && (
                  <ErrorMessage message={errors.password[0]} />
                )}
              </div>
              <div className='col-span-12 space-y-1.5 text-left'>
                {!isSendingVerifyEmail ? (
                  <>
                    <Label className='text-xs' htmlFor='set-email'>
                      Email
                    </Label>
                    <div className='flex items-center gap-2'>
                      {confirmedOfficialEmail ? (
                        <Input value={email} disabled />
                      ) : (
                        <Input
                          type='email'
                          id='set-email'
                          required
                          onChange={(evt) => setEmail(evt.target.value)}
                          className={`border-gray-300 shadow-sm bg-white/5 hover:bg-white/10 focus-visible:ring-0 ${
                            emailVerificationLoading
                              ? "animate-pulse opacity-80"
                              : ""
                          }`}
                        />
                      )}
                      {startup && !confirmedOfficialEmail && (
                        <Button
                          onClick={onSendToken}
                          disabled={emailVerificationLoading}
                        >
                          {emailVerificationLoading
                            ? "Processing..."
                            : "Verify"}
                        </Button>
                      )}
                      {startup && confirmedOfficialEmail && (
                        <div className='inline-flex items-center h-10 px-4 space-x-2 text-sm font-medium text-white bg-green-600 rounded-md'>
                          <p>Verified</p>
                          <CheckCircle weight='fill' />
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  (isSendingVerifyEmail || emailVerificationLoading) && (
                    <div className='w-full space-y-1.5 flex justify-center flex-col items-center gap-y-2 mt-3 mb-2 bg-white p-3 rounded'>
                      <Label className='text-xs text-gray-800'>
                        Verification Code
                      </Label>
                      <OTPInput
                        value={confirmToken}
                        onChange={setConfirmToken}
                        numInputs={6}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => (
                          <input disabled={isLoading} {...props} />
                        )}
                        inputStyle='border rounded w-full text-4xl mx-2 disabled:border-slate-400 text-slate-400'
                      />
                      {startup && (
                        <>
                          <Button variant='secondary' onClick={onVerifyToken}>
                            {emailVerificationLoading
                              ? "Processing..."
                              : "Verify"}
                          </Button>
                          <Button
                            size='sm'
                            onClick={onSendToken}
                            className='mt-4 text-xs text-sm border-0 bg-background/5 hover:bg-background/10 text-slate-600'
                          >
                            {emailVerificationLoading
                              ? "Processing..."
                              : "Resend Code"}
                          </Button>
                        </>
                      )}
                    </div>
                  )
                )}

                {startup && !confirmedOfficialEmail && (
                  <p className='mt-1 text-xs opacity-40'>
                    {!isSendingVerifyEmail
                      ? "Please input your startup official email to continue"
                      : `Please input verification token sent to ${email}`}
                  </p>
                )}
                {errors && errors.email && (
                  <ErrorMessage message={errors.email[0]} />
                )}
              </div>
              <div className='col-span-12 space-y-1.5 text-left'>
                <Label className='text-xs' htmlFor='set-password'>
                  Password
                </Label>
                <div className='relative'>
                  <Input
                    type={showPassword ? "text" : "password"}
                    id='set-password'
                    required
                    onChange={(evt) => setPassword(evt.target.value)}
                    className='border-gray-300 shadow-sm bg-white/5 hover:bg-white/10 focus-visible:ring-0'
                  />
                  <button
                    type='button'
                    onClick={() => setShowPassword(!showPassword)}
                    className='absolute z-10 p-1 transition-colors -translate-y-1/2 right-3 top-1/2 text-black/60 hover:text-black'
                    aria-label={
                      showPassword ? "Hide password" : "Show password"
                    }
                  >
                    {showPassword ? (
                      <EyeSlash size={20} className='text-current' />
                    ) : (
                      <Eye size={20} className='text-current' />
                    )}
                  </button>
                </div>
                {errors && errors.password && (
                  <ErrorMessage message={errors.password[0]} />
                )}
              </div>

              <div className='col-span-12'>
                <Label className='flex items-start gap-2 text-left'>
                  <Checkbox
                    checked={checkedNewsletter}
                    onCheckedChange={handleCheckboxChange}
                    className='bg-white border shadow-sm border-background/10'
                  />
                  <span className='font-medium leading-tight cursor-pointer'>
                    Subscribe to our newsletter
                  </span>
                </Label>
                {!checkedNewsletter && (
                  <p className='mt-1 text-xs text-red-500'>
                    Please subscribe to our newsletter to continue.
                  </p>
                )}
              </div>
              <div className='flex flex-col items-center col-span-12 gap-4'>
                <Button
                  type='submit'
                  variant='secondary'
                  className='w-full'
                  disabled={
                    isLoading ||
                    (startup && !confirmedOfficialEmail) ||
                    !checkedNewsletter
                  }
                >
                  {isLoading && (
                    <CircleNotch
                      weight='bold'
                      className='mr-1.5 text-lg animate-spin'
                    />
                  )}
                  {isLoading ? "Processing..." : "Register"}
                </Button>

                {!startup && (
                  <div className='flex flex-col w-full gap-4'>
                    <div className='flex items-center w-full -my-1 space-x-3'>
                      <span className='flex-1 h-px bg-background/10'></span>
                      <span className='text-[10px] uppercase font-medium tracking-wider'>
                        or
                      </span>
                      <span className='flex-1 h-px bg-background/10'></span>
                    </div>
                    <GoogleSignInButton
                      label='Register with Google'
                      disabled={!checkedNewsletter}
                    />
                  </div>
                )}
              </div>
            </form>
            <div className='flex justify-center'>
              <button
                type='button'
                onClick={switchPopup}
                className='mt-4 text-xs font-medium underline opacity-60'
              >
                I have an account
              </button>
            </div>
          </div>
        ) : (
          <InputTokenForAccountRegister
            {...{
              isVisible: registerCodeVerifyInputOpen ? true : openRegisterPopup,
              onVerifyTokenUserRegister,
              emailVerificationLoading,
              confirmToken,
              setConfirmToken,
              isLoading,
              onSendTokenForAccountRegistration,
              sendEmailVerificationLoading,
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Register;
