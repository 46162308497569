import dayjs from "dayjs";

export const SITEMAP_CHUNK_SIZE = 10000;

export const createUrl = (path) => `${process.env.BASE_URL}${path}`;

export const formatLastmod = (date) =>
  dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ");

export const mapContentTypeToPath = {
  Videos: "videos",
  "Special Reports": "reports",
  Spotlights: "spotlights",
  Infobytes: "infobytes",
  Articles: "articles",
  News: "news",
  Opinions: "opinions",
  "Podcast Episodes": "podcasts-masterclasses",
  Startups: "startups",
  Investors: "investors",
};

export const chunkArray = (array, size) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
}; 